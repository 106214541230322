import React from "react";

import Layout from "../components/Layout";
import ProdutosHome from "../pagesFiles/home/produtos";
import DepoimentosHome from "../pagesFiles/home/depoimentos";
import DetalhesHome from "../pagesFiles/home/detalhes";
import ClientesHome from "../pagesFiles/home/clientes";
import ApresentacaoHome from "../pagesFiles/home/apresentacao";
import SobreHome from "../pagesFiles/home/sobre";
import HeroHome from "../pagesFiles/home/hero";
import Seo from "../components/Seo";

export default function Index() {
  return (
    <Layout>
      <Seo
        title="Home"
        description="Taxcel combina experiência fiscal no complicado sistema tributário brasileiro, com tecnologia inovadora para simplificar a gestão direto em seu Excel e Power BI
"
      />
      <HeroHome />
      <DetalhesHome paralax />
      <ProdutosHome white />
      <DepoimentosHome />
      <ClientesHome />
      <ApresentacaoHome />
      <SobreHome />
    </Layout>
  );
}
