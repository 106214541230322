import styled from "styled-components";
import { Container as Wrapper } from "../../styles/UI";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 0;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.bp.veryBigDesktop}) {
    overflow: ${(p) => (p.$clip ? "hidden" : "visible")};
  }
`;

export const Container = styled(Wrapper)`
  .cta {
    position: relative;
    z-index: 10;

    h1 {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: -0.01em;
      margin-bottom: 24px;
      line-height: 1.25;
    }

    p {
      font-weight: 350;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .illustration {
    z-index: 1;
    margin-top: 32px;
    position: relative;

    @media (min-width: ${(p) => p.theme.bp.desktop}) {
      margin-top: 16px;
      height: 480px;
    }

    @media (min-width: ${(p) => p.theme.bp.veryBigDesktop}) {
      margin-top: 32px;
      height: 100%;
    }

    .bg-logo {
      position: absolute;
      z-index: 0;
      height: 800px;
      right: -180px;
      top: -240px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        left: 60px;
        top: -240px;
        height: 980px;
      }
    }

    .icon1 {
      position: absolute;
      bottom: 124px;
      left: 32px;
      width: 70px;
      z-index: 11;

      box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.25);
      border-radius: 70px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        left: 100px;
        bottom: 220px;
      }
    }

    .icon2 {
      position: absolute;
      right: 0;
      bottom: 142px;
      width: 124px;
      z-index: 1;

      box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.25);
      border-radius: 8px;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        width: 164px;
        z-index: 10;
        bottom: 220px;
      }
    }
  }

  /* Button on Hero */
  & button {
    box-shadow: 0px 8px 16px -4px #05185b;
  }

  & button:hover {
    background-color: var(--color-white);
  }

  .hero-illustration {
    /* filter: drop-shadow(14px 24px 80px rgba(0, 0, 0, 0.65)); */
    z-index: 10;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    max-height: 820px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    align-items: center;
  }
`;
