import styled from "styled-components";

export const HeroCard = styled.div`
  background-color: white;
  border-radius: 0 16px 0 16px;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.1);
  margin-top: ${(p) => (p.$mt ? p.$mt : "-42")}px;
  position: relative;
  padding: 20px;
  z-index: 1;
  color: ${(p) => p.theme.textDarkGray};

  @media (max-width: ${(p) => p.theme.bp.tablet}) {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  a, div {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: ${(p) => p.theme.textDarkGray};
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin-right: 24px;
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;

      @media (max-width: ${(p) => p.theme.bp.tablet}) {
        font-size: 15px;
      }
    }
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 24px 44px;
    margin-top: -42px;

    a, div {
      margin-bottom: 0;
    }
  }
`;
