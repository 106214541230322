import styled from "styled-components";
import { Container } from "../../../styles/UI";

export const Detalhes = styled.section`
  margin-top: 54px;
  color: ${(p) => p.theme.textDarkGray};
  position: relative;
  padding-bottom: 4px;

  &:after {
    display: block;
    content: "";
    height: 8px;
    width: 80%;
    border-radius: 0 0 0 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: ${(p) => p.theme.laranja};
    transform: translateY(100%);
    z-index: 100;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      width: calc(50% - 16px);
    }
  }

  .paralax {
    display: none;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    ${Container} {
      position: relative;
    }

    .paralax {
      display: block;
      position: absolute;
      left: -372px;
      top: -212px;
    }

    & > div {
      margin-top: 80px;
      padding-bottom: 80px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 32px;
      grid-row-gap: 80px;

      .grid-1 {
        grid-row: 1;
        grid-column: 1;
      }

      .grid-2 {
        grid-row: 1;
        grid-column: 2;
      }

      .grid-3 {
        grid-row: 1 / span 2;
        grid-column: 1;
        align-self: end;
        margin-bottom: 48px;
        height: 570px;
      }

      .grid-4 {
        grid-row: 2;
        grid-column: 2;
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 29px;
    line-height: 40px;
    /* or 138% */

    letter-spacing: -0.01em;

    /* Azul Principal */

    color: #0046d7;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      font-size: 36px;
      line-height: 44px;
      /* or 123% */
      max-width: 480px;
    }
  }

  h2 + div p {
    margin: 24px 0 32px;
    font-weight: 350;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      margin: 0 0 32px;
    }
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    font-size: 16px;
    font-weight: 350;
    box-sizing: border-box;
    margin-bottom: 32px;

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      margin: 0;
    }

    li {
      margin-left: 32px;
      line-height: 32px;

      &::marker {
        font-size: 80%;
        margin: 0;
      }
    }
  }

  h3 {
    margin: 32px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.0125rem;
    /* or 161% */

    /* Azul Principal */

    color: ${(p) => p.theme.primary};

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      margin: 0 0 24px;
    }
  }

  .bubble-wrapper {
    margin-bottom: 32px;

    .bubble-container {
      display: flex;

      @media (min-width: ${(p) => p.theme.bp.tablet}) {
        max-width: 416px;
      }

      div:first-child {
        flex-shrink: 0;
      }

      div:last-child {
        margin-left: 24px;
        margin-bottom: 24px;

        h4 {
          font-size: 20px;
          line-height: 48px;

          color: ${(p) => p.theme.textDarkGray};
        }

        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          /* or 150% */

          color: ${(p) => p.theme.textGray};
        }
      }
    }
  }
`;